.fin-tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.fin-tooltip .fin-tooltiptext {
  visibility: hidden;
  width: 350px;
  font-size: 14px;
  color: var(--text-main-brighter);
  background-color: var(--overlay-light);
  text-align: left;
  border-radius: 6px;
  padding: 4px;

  /* Position the tooltip */
  position: absolute;
  z-index: 2000;
}

.fin-tooltip:not(.disabled):hover .fin-tooltiptext:not(.disabled) {
  visibility: visible;
}
