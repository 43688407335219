/* ===============================
          visibility
   =============================== */

.fin-display {
  display: block;
}

.fin-display-none {
  display: none;
}

.fin-visible {
  visibility: visible;
}

.fin-hidden {
  visibility: hidden;
}

/* ===============================
          General layout
   =============================== */

.fin-width-20-perc {
  width: 20%;
}

.fin-width-33-perc {
  width: 33%;
}

.fin-width-59-perc {
  width: 59%;
}

/* ===============================
          Cursor style
   =============================== */

.fin-cursor-pointer {
  cursor: pointer;
}

.fin-cursor-default {
  cursor: default;
}


/* ===============================
          General mark-up
   =============================== */

.fin-italic {
   font-style: italic;
}

.fin-accented {
   font-weight: 400;
   color: var(--filter-1-select-partial);
}

.fin-centered {
  text-align: center;
}
