
.fin-input-group {
  padding: 0px 8px 0px 8px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
}

.fin-input-group label {
  width: 200px;
  padding: 4px 0px 4px 0px;
  font-weight: lighter;
  font-size: 1em;
}

.fin-input-group input {
  width: 500px;
  font-size: 1em;
  color: var(--text-main);
  background-color: var(--overlay-dark);
  border: 1px solid var(--accent-1-selected);
  outline: none;
  border-radius: 5px;
  font-weight: lighter;
}

.fin-input-group input:focus {
  color: var(--text-main-brighter);
  border: 1px solid var(--accent-1-full);
  background-color: var(--overlay-light);
}

.fin-input-group select {
  width: 500px;
  height: 28px;
  font-size: 1em;
  color: var(--text-main);
  background-color: var(--overlay-dark);
  border: 1px solid var(--accent-1-selected);
  outline: none;
  border-radius: 5px;
  font-weight: lighter;
}

.fin-input-group select:focus {
  color: var(--text-main-brighter);
  border: 1px solid var(--accent-1-full);
  background-color: var(--overlay-light);
}

.fin-input-group input[type=checkbox] {
  width: 20px;
  height: 28px;
  margin: 0;
  padding: 6px 0px 0px 0px;
  /* font-size: 1em; */
  /* color: var(--text-main); */
  /* background-color: var(--overlay-dark); */
  /* border: 1px solid var(--accent-1-selected); */
  /* outline: none; */
  /* border-radius: 5px; */
  /* font-weight: lighter; */
}
