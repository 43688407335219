/* ----------------------------------
          Generic buttons
   ---------------------------------- */

.fin-buttonlist {
  display: flex;
  flex-flow: row nowrap;
  padding: 4px 4px 4px 0px;
}

.fin-button {
  box-sizing: border-box;
  height: 28px;
  width: 130px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 2px 4px 2px 0px;
  border-radius: 6px;
}

.fin-button.enabled {
  color: var(--btn-text);
  background-color: var(--btn-background);
  padding: 2px 0px 0px 0px;  /* ivm geen border */
  cursor: pointer;
}

.fin-button.disabled {
  color: var(--btn-disabled);
  border: 1px solid var(--btn-disabled);
  padding: 1px 0px 0px 0px;  /* ivm wel border */
  cursor: default;
}

.fin-button.enabled:hover {
  color: var(--btn-text-hover);
  background-color: var(--btn-background-hover);
}

.fin-button.enabled:active {
  background-color: var(--btn-background-active);
}

.fin-close-button {
  cursor: pointer;
}

.fin-close-button:hover {
  color: var(--filter-1-select-full);
}

/* ----------------------------------
      Radio buttons isInvoerscherm
   ---------------------------------- */

.fin-radio-button {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  height: 24px;
  width: 150px;
  border-radius: 5px;
  margin: 2px 6px 2px 0px;
  padding: 1px 0px 0px 0px;
  cursor: default;
}

.fin-radio-button.selected {
  border: 1px solid var(--accent-1-full);
  background-color: var(--accent-1-selected);
}

.fin-radio-button.deselected {
  border: 1px solid var(--accent-1-partial);
  background-color: var(--overlay-dark);
}

.fin-radio-button.selected:hover {
  border: 1px solid var(--accent-1-full);
  background-color: var(--accent-1-selected);
}

.fin-radio-button.deselected:hover {
  border: 1px solid var(--accent-1-full);
  background-color: var(--accent-1-selected);
  cursor: pointer;
}
