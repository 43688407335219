
.fin-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--overflow-transparent);
}

.fin-modal-container {
  border-radius: 20px;
  padding: 0px;
  margin: 5% auto;
  width: 70%;
  min-width: 600px;
  color: var(--text-main);
  background-color: var(--surface);
}

.fin-modal-header {
  height: 70px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;   /*Nagaan waarom dit nodig is */
  border-radius: 20px 20px 0px 0px;
  margin: 0;
  Padding: 18px 8px 20px 8px;
  background-color: var(--base);
}

.fin-modal-header-title {
  font-size: 24px;
  font-weight: 300;
}

.fin-modal-body {
  padding: 8px 0px 0px 0px;
  height: 500px;
}

.fin-modal-footer {
  height: 70px;
  width: 100%;
  box-sizing: border-box;   /*Nagaan waarom dit nodig is */
  border-radius: 0px 0px 20px 20px;
  margin: 0;
  Padding: 10px 8px 12px 8px;
  background-color: var(--base);
}
