.fin-flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.fin-flex-start {
  justify-content: flex-start;
}

.fin-flex-center {
  justify-content: center;
}

.fin-flex-end {
  justify-content: flex-end;
}
