:root {
  font-family: 'Quicksand', sans-serif;

  --text-main                    : rgba(255, 255, 255, 0.65);
  --text-main-brighter           : rgba(255, 255, 255, 0.8);
  --text-main-darker             : rgba(255, 255, 255, 0.4);

  --overlay-light                : #383B40;
  --overlay-dark                 : #2D2F34;
  --surface                      : #27292D;
  --base                         : rgba(31, 32, 35, 1);
  --subbase                      : rgba(20, 21, 23, 1);
  --overflow                     : rgba(9, 9, 9, 1);
  --overflow-transparent         : rgba(9, 9, 9, 0.5);

  --status-open                  : rgba(61, 108, 148, 0.8);
  --status-open-hover            : rgba(61, 108, 148, 1);
  --status-complete              : rgba(71, 140, 63, 0.8);
  --status-complete-hover        : rgba(71, 140, 63, 1);

  --btn-background               : rgba(50, 133, 179, 0.6);
  --btn-background-hover         : rgba(50, 133, 179, 0.8);
  --btn-background-active        : rgba(50, 133, 179, 0.9);
  --btn-disabled                 : var(--overlay-light);
  --btn-text                     : rgba(21, 46, 61, 1);
  --btn-text-hover               : rgba(21, 46, 61, 0.7);

  /* bronze */
  --accent-1-full                : rgba(189, 125, 40, 0.7);
  --accent-1-partial             : rgba(189, 125, 40, 0.45);
  --accent-1-selected            : rgba(189, 125, 40, 0.2);
  --accent-1-hover               : rgba(189, 125, 40, 0.3);
/*opruimen/betere naam geven */
  --filter-1-select-full         : #a87736;
  --filter-1-select-partial      : #947348;

  --info-icon                    : var(--text-main-darker);
  --error-icon                   : rgba(201, 73, 40, 0.8);
  --tool-tip-bg                  : rgba(110, 69, 15);
}

html, body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*

- Alle styles hebben een pre-fix:
  - 'fin' voor alles in dit main.css en imports
  - 'tmp' als er nog aan gewerkt wordt
  - ander per sectie bv 'bk' / 'inz' etc
  - uitgezonderd stylen op standaard componenten als 'body' ;-)

Op te schonen:
  - cfg en bk grid en regels naar 1 standaard style brengen
  - --filter-1-select verwijderen (oppakken bij bovendstaande)
  - kleuren uit naam van statussen

*/

@import './modules/structure-base.css';
@import './modules/structure-modal.css';
@import './modules/regel.css';
@import './modules/general.css';
@import './modules/flex.css';
@import './modules/input.css';
@import './modules/filter.css';
@import './modules/divider.css';
@import './modules/button.css';
@import './modules/tooltip.css';
@import './modules/error.css';
