/* ----------------------------
           Container
   ---------------------------- */

.fin-filter-container {
  box-sizing: border-box;
  border: none;
  height: 100%;
  color: var(--text-main);
  background-color: var(--overflow);
}

.fin-filterheader {
  font-size: 24px;
  width: 100%;
  height: 33px;
  padding: 8px 0px 0px 0px;
  text-align: center;
  cursor: default;
}

/* ----------------------------
     Hoofdstructuur filterblok
   ---------------------------- */

.fin-filter {
  width: 134px;
  padding: 0;
  margin: 12px 8px 0px 2px;
  border-left: 2px solid var(--overflow);
}

.fin-filterlistheader {
  height: 24px;
  background-color: var(--subbase);
  cursor: default;
}

.fin-filterlistheader-margin {
  width: 4px;
  height: 24px;
  margin: 0;   /* nagaan */
  padding: 0;  /* nagaan */
  background-color: var(--overflow);
}

.fin-filterlistheader-title {
  width: calc(100% - 4px - 24px);
  font-size: 16px;
  text-align: left;
  padding: 0px 0px 3px 3px;
}

.fin-filterlistheader-icon {
    width: 24px;
    font-weight: bold;
    text-align: center;
    padding: 0;  /* nagaan */
    margin: 0;  /* nagaan */
}

.fin-filterlistbody {
  font-size: 18px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--base);
}

.fin-filterlist {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ----------------------------
      Standaard filter regel
   ---------------------------- */

.fin-filterregel {
  height: 20px;
  font-size: 15px;
  padding: 3px 0px 0px 4px;
  cursor: pointer;
}

.fin-filterregel-deselected {
  font-weight: 200;
  border-left: 4px solid var(--overflow);
}

.fin-filterregel-selected {
  font-weight: 400;
  border-radius: 4px 0px 0px 4px;
  border-left: 4px solid var(--accent-1-full);
}

.fin-filterregel:hover {
  border-radius: 4px 0px 0px 4px;
  border-left: 4px solid var(--accent-1-partial);
  background-color: var(--accent-1-hover);
}

/* ----------------------------
      Jaarmaand filter regel
   ---------------------------- */

.fin-jaartal-header {
  color: var(--text-main);
  background-color: var(--surface);
  height: 24px;
  width: 130px;
  font-size: 14px;
  padding: 0;
  margin: 0;
  border-left: 4px solid var(--overflow);
  cursor: default;
}

.fin-jaartal-header-jaar {
  width: 106px;  /* 130px - 24px */
  padding: 4px 0px 0px 4px;
  cursor: pointer;
}

.fin-jaartal-selected {
  font-weight: 400;
  border-radius: 4px 0px 0px 4px;
  border-left: 4px solid var(--accent-1-full);
}

.fin-jaartal-partial-selected {
  font-weight: 300;
  font-style: italic;
  border-radius: 4px 0px 0px 4px;
  border-left: 4px solid var(--accent-1-hover);

}

.fin-jaartal-header:hover {
  border-radius: 4px 0px 0px 4px;
  border-left: 4px solid var(--accent-1-partial);
  background-color: var(--accent-1-hover);
}
