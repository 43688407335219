
.fin-regel {
  font-size: 15px;
  font-weight: 300;
  color: var(--text-main);
  background-color: var(--base);
}

.fin-regel-hover {
  background-color: var(--surface);
}

.fin-regel-cel {
   padding: 2px;
}

/* ---------------------------------
    Vertical spacers en separators
   --------------------------------- */

.fin-regel-vspacer {
 padding: 0px;
 margin: 0px;
}

.fin-regel-vseparator {
  width: 3px;
  padding: 0px;
  margin: 0px;
  background-color: var(--subbase);
}

.fin-regel-vseparator-hover {
  background-color: var(--base);
}

/* -------------------
      Status tab
   ------------------- */

.fin-regel-tab {
  box-sizing: border-box;
  width: 5px;
  border-style: solid;
  border-radius: 5px 0px 0px 5px;
  border-color: var(--subbase);
}

.fin-regel-tab.open {
  border-color: var(--status-open);
}

.fin-regel-tab.complete {
  border-color: var(--status-complete);
}

.fin-regel-tab.open-hover {
  border-color: var(--status-open-hover);
}

.fin-regel-tab.complete-hover {
  border-color: var(--status-complete-hover);
}

/* -------------------
      Icons

   vier weergaven
   - invisible  (basis, altijd onzichtbaar)
   - enabled    (witte outline on regelhover & eigen hover volle kleur)
   - shaded     (minder kleur & geen aparte hover state, geen pointer)
   - locked     (volle kleur & geen aparte hover state, geen pointer)
   - selected   (volle kleur, geen regelhover & accent kleur bij eigen hover )
   - dragdrop   (gelijk enabled, maar met move cursor)

   ------------------- */

.fin-regel-icon {
  width: 20px;
  padding-top: 4px;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}

.fin-regel-icon.invisible {
  visibility: hidden;
}

.fin-regel-icon.enabled {
  color: var(--text-main);
  cursor: pointer;
}

.fin-regel-icon.enabled:hover {
  color: var(--filter-1-select-full);
}

.fin-regel-icon.shaded {
  color: var(--accent-1-hover);
  cursor: default;
}

.fin-regel-icon.locked {
  color: var(--filter-1-select-partial);
  cursor: default;
}

.fin-regel-icon.selected {
  color: var(--filter-1-select-partial);
  cursor: pointer;
}

.fin-regel-icon.selected:hover {
  color: var(--filter-1-select-full);
}

.fin-regel-icon.selected-hover {
  color: var(--filter-1-select-full);
  cursor: pointer;
}

.fin-regel-icon.dragdrop {
  color: var(--text-main);
  cursor: move;
}

.fin-regel-icon.dragdrop:hover {
  color: var(--filter-1-select-full);
  cursor: move;
}
