
/* Dit zit op de 'router' componenten */

/* Hier wordt wel aan gerefereerd, maar is functioneel leeg */
.fin-full-screen-container {
/*
   background-image: url("../../assets/vault-50233841.jpg");
   height: 100vh;
   width: 100vw;
   background-size: cover;
   background-position: center;
   background-attachment: fixed;
*/
}

.fin-left-sidebar {
    position: fixed;
    top: 50px;
    left: 0;
    width: 150px;
    height: 100%;
    flex-shrink: 0;
    z-index: 500;
}



/* Dit zit op de 'body' componenten */
.fin-body-container {
  width: 100%;
  height: 100%;
  padding: 10px 8px 8px 10px;
  color: var(--text-main);
  background-color: var(--subbase);
}
