
/* ===============================
          Error handling
  =============================== */

/* te herzien */

.fin-error-text {
  color: red;
  font-size: bold;
}
/*
.fin-error-icon {
  width: 20px;
  color: var(--error-icon);
  padding: 6px 4px 2px 4px;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  cursor: default;
} */

.fin-error-icon {
  width: 20px;
  color: var(--error-icon);
  padding: 6px 6px 2px 6px;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  cursor: default;
}
