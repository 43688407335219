
/* -----------------------------
        Horizontal dividers
   ----------------------------- */

.fin-divider-base {
  height: 2px;
  width: 100%;
  padding: 0;
  background-color: var(--base);
}

.fin-divider-base-4 {
  height: 2px;
  width: 100%;
  padding: 0;
  background-color: var(--base);
}

.fin-divider-surface-1 {
  height: 1px;
  width: 100%;
  padding: 0;
  background-color: var(--surface);
}

.fin-divider-surface {
  height: 2px;
  width: 100%;
  padding: 0;
  background-color: var(--surface);
}

.fin-divider-subbase-3 {
  height: 3px;
  width: 100%;
  padding: 0;
  background-color: var(--subbase);
}

/* Nieuwe stijl */

.fin-divider {
  width: 100%;
  padding: 0;
  margin: 0;
}

.fin-spacer {
 height: 100%;
 padding: 0px;
 margin: 0px;
}

div.fh1 { height: 1px; }
div.fh2 { height: 2px; }
div.fh3 { height: 3px; }
div.fh4 { height: 4px; }

div.fw1 { width:1px; }
div.fw2 { width:2px; }
div.fw3 { width:3px; }
div.fw4 { width:4px; }
div.fw5 { width:5px; }

div.fbg-subbase           { background-color: var(--subbase); }
div.fbg-base              { background-color: var(--base); }
div.fbg-surface           { background-color: var(--surface); }
div.fbg-text-main         { background-color: var(--text-main);}
div.fbg-text-main-darker  { background-color: var(--text-main-darker);}
